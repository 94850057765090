import React, {useEffect} from 'react';

import Iframe from 'react-iframe'

/* Ion Components */
import { IonButton, IonInput, IonItem, IonLabel, IonList } from '@ionic/react';

/*Hooks*/
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { VideoConferenceHooks } from '../../hooks/videoConferenceHooks';


interface ContainerProps { }

const VideoConferenceContainer: React.FC<ContainerProps> = () => {
    const {
        getRoom,
        roomState
    } = VideoConferenceHooks()

    const openSmth = () => {
         InAppBrowser.create('https://test.dev-optima.com/hello_world', '_parent', 'allowInlineMediaPlayback=yes');
    }
    // useEffect(() => {
    //     getRoom().then()
    //     console.log(roomState);
    // },[getRoom, roomState])
    return (
        <div className="video_conference_container">
            <iframe
                // style={{width: "100vw", height: "100vh"}}
                allow="camera *;microphone *"
                title="Insert any random name you wish here"
                src="https://test.dev-optima.com/hello_world"
            >
                фівфівфів
            </iframe>

            <Iframe url="https://test.dev-optima.com/hello_world" />
            <IonButton
                onClick={ openSmth }
            >
                Open
            </IonButton>
        </div>
    );
};

export default VideoConferenceContainer;
