import { useState } from 'react';

export const RegisterFormHooks = () => {
    const [mobilePhoneRegister, setMobilePhoneRegister] = useState<string>('')
    const [confirmCodeRegister, setConfirmCodeRegister] = useState<string>('')

    return {
        setMobilePhoneRegister(e: any) {
            setMobilePhoneRegister(e.target.value)
        },
        setConfirmCodeRegister(e: any) {
            setConfirmCodeRegister(e.target.value)
        },
        checkMobilePhoneRegister() {
            console.log('mobilePhoneRegister----->', mobilePhoneRegister)
        },
        checkConfirmCodeRegister() {
            console.log('confirmCodeRegister----->', confirmCodeRegister);
        },
        mobilePhoneRegister,
        confirmCodeRegister
    }
}
