/* Ion Components */
import { IonButton } from '@ionic/react';
/* Hooks */
import { HomeHooks } from '../hooks/homeHooks';

interface ContainerProps { }

const HomeContainer: React.FC<ContainerProps> = () => {
    const {
        goToVideoConfBooking,
        goTesting
    } = HomeHooks()
  return (
    <div className="home_container">
        <IonButton>Store</IonButton>
        <IonButton
            onClick={goToVideoConfBooking}
        >
            Book video conference
        </IonButton>
        <p>FOR TEST_______________________</p>
        <IonButton
            onClick={() => {goTesting('/login')}}
        >
            Login
        </IonButton>
        <IonButton
            onClick={() => {goTesting('/register')}}
        >
            Register
        </IonButton>
        <IonButton
            onClick={() => {goTesting('/videoConference')}}
        >
            video conference
        </IonButton>
    </div>
  );
};

export default HomeContainer;
