/* Ion Components */
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

/* Components */
import RegisterForm from '../../components/RegisterForm';

// Styles
import './styles.scss'

const Register: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Register</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <RegisterForm />
            </IonContent>
        </IonPage>
    );
};

export default Register;
