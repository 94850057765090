/* Ion Components */
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

/* Components */
import VideoConfBookingContainer from '../../components/VideoConfBookingContainer';

/* Styles */
import './styles.scss'

const VideoConfBooking: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Book Video Conference</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <VideoConfBookingContainer />
            </IonContent>
        </IonPage>
    );
};

export default VideoConfBooking;
