/* Ion Components */
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

/* Components */
import VideoConferenceContainer from '../../components/VideoConferenceContainer';

/* Styles */
import './styles.scss'

const VideoConference: React.FC = () => {

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Video Conference</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <VideoConferenceContainer />
            </IonContent>
        </IonPage>
    );
};

export default VideoConference;
