/* Ion Components */
import {IonItem, IonLabel, IonList, IonDatetime, IonButton, useIonPicker} from '@ionic/react';

/*Hooks*/
import { VideoConfBookingHooks } from '../../hooks/videoConfBookingHooks';

interface ContainerProps { }

const VideoConfBookingContainer: React.FC<ContainerProps> = () => {

    const {
        setConfDurationText,
        setConfDurationValue,
        confDuration: {confDurationText, confDurationValue},
        setConfDatetime,
        confDatetime
    } = VideoConfBookingHooks()
    const [present] = useIonPicker();
    return (
        <div className="video_conf_booking_container">
            <IonList>
                <IonItem>
                    <IonLabel>Datetime</IonLabel>
                    <IonDatetime
                        min="2018"
                        max="2099-10-31"
                        displayFormat={"MMM DD, YYYY HH:mm"}
                        onIonChange={(e) => {setConfDatetime(e)}}
                        value={confDatetime}
                    />
                </IonItem>

                <IonItem>
                    <IonLabel>{confDurationText ? `Duration: ${confDurationText}` : "Duration"}</IonLabel>
                    <IonButton
                        expand="block"
                        onClick={() =>
                            present({
                                buttons: [
                                    {
                                        text: 'Done',
                                        handler: (selected) => {
                                            setConfDurationValue(selected.duration.value)
                                            setConfDurationText(selected.duration.text)
                                            console.log(confDurationValue)
                                        },
                                    },
                                ],
                                columns: [
                                    {
                                        name: 'duration',
                                        options: [
                                            { text: '30 min', value: 30 },
                                            { text: '45 min', value: 45 },
                                            { text: '1 hour', value: 60 },
                                            { text: '1.15 hours', value: 75 },
                                            { text: '1.30 hours', value: 90 },
                                        ],
                                    },
                                ],
                            })
                        }
                    >
                        Set Duration
                    </IonButton>
                </IonItem>
            </IonList>
        </div>
    );
};

export default VideoConfBookingContainer;
