import { useHistory } from 'react-router';

export const HomeHooks = () => {

    const history = useHistory()

    return {
        goToVideoConfBooking() {
            history.push('/bookVideoConf')
        },
        goTesting(path:any) {
            history.push(`${path}`)
        },
    }
}
