import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

// Components
import LoginForm from '../../components/LoginForm';
// Styles
import './styles.scss'

const Login: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <LoginForm/>
            </IonContent>
        </IonPage>
    );
};

export default Login;
