import axios from 'axios';
import {useState} from 'react';

export const VideoConferenceHooks = () => {
    const [roomState, setRoomState] = useState({})
    return {
        getRoom : async () =>{
            try {
                const room = await axios.get('http://localhost:3000/xroom/list').then((res)=>{
                    return setRoomState(res);
                })
            } catch (e) {
                console.log(e)
            }
        },
        roomState
    }
}
