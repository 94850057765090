import React from 'react';
/* Hooks */
import  { LoginFormHooks } from "../../hooks/loginFormHooks";

/* Ion Components */
import { IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';

interface ContainerProps { }

const LoginForm: React.FC<ContainerProps> = () => {
    const {
        goToRegisterPage,
        setMobilePhoneLogin,
        setConfirmCodeLogin,
        checkMobilePhoneLogin,
        checkConfirmCodeLogin,
        mobilePhoneLogin,
        confirmCodeLogin
    } = LoginFormHooks()

    return (
        <div className="login_container">
            <IonList
                inset={true}
                lines="none"
            >
                <IonItem
                    lines="full"
                >
                    <IonLabel
                        position="floating"
                    >
                        Mobile Phone
                    </IonLabel>

                    <IonInput
                        onIonChange={e => setMobilePhoneLogin(e)}
                        value={mobilePhoneLogin}
                    />

                    <IonButton
                        slot="end"
                        onClick={checkMobilePhoneLogin}
                        disabled={
                            mobilePhoneLogin.length < 6
                        }
                    >
                        Send Code
                    </IonButton>
                </IonItem>

                <IonItem>
                    <IonLabel
                        position="floating"
                    >
                        Confirm SMS Code
                    </IonLabel>

                    <IonInput
                        onIonChange={e => setConfirmCodeLogin(e)}
                    />

                    <IonButton
                        slot="end"
                        onClick={checkConfirmCodeLogin}
                        disabled={
                            confirmCodeLogin.length < 6
                        }
                    >
                        Confirm
                    </IonButton>
                </IonItem>

                <IonItem
                    class="ion-no-padding"
                    lines="none"
                >
                    <div
                        className="no_account"
                    >
                        <IonButton
                            onClick={() => {
                                goToRegisterPage()
                            }}
                        >
                            Register
                        </IonButton>
                    </div>
                </IonItem>
            </IonList>
        </div>
    );
};

export default LoginForm;
