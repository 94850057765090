import { SetStateAction, useState } from 'react';


export const VideoConfBookingHooks = () => {
    const currentDate = new Date().toJSON().slice(0,10)
    const [confDuration, setConfDuration] = useState({confDurationText: '', confDurationValue: 0})
    const [confDatetime,setConfDatetime] = useState(currentDate)

    return {
        setConfDurationValue(param: SetStateAction<any>) {
            setConfDuration((prev) => ({...prev, confDurationValue : param}))
        },
        setConfDurationText(param: SetStateAction<any>) {
            setConfDuration((prev) => ({...prev, confDurationText : param}))
        },
        setConfDatetime(e: any) {
            setConfDatetime(e.target.value)
        },
        confDuration,
        confDatetime
    }
}
