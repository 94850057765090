import React from 'react';
/* Ion Components */
import { IonButton, IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
/*Hooks*/
import { RegisterFormHooks } from '../../hooks/registerFormHooks';


interface ContainerProps { }

const RegisterForm: React.FC<ContainerProps> = () => {
    const {
        setMobilePhoneRegister,
        setConfirmCodeRegister,
        checkMobilePhoneRegister,
        checkConfirmCodeRegister,
        mobilePhoneRegister,
        confirmCodeRegister,
    } = RegisterFormHooks()

    return (
        <div className="register_container">
            <IonList
                inset={true}
                lines="none"
            >
                <IonItem
                    lines="full"
                >
                    <IonLabel
                        position="floating"
                    >
                        Mobile Phone
                    </IonLabel>

                    <IonInput
                        onIonChange={e => setMobilePhoneRegister(e)}
                    />

                    <IonButton
                        slot="end"
                        onClick={checkMobilePhoneRegister}
                        disabled={
                            mobilePhoneRegister.length < 6
                        }
                    >
                        Send Code
                    </IonButton>
                </IonItem>

                <IonItem>
                    <IonLabel
                        position="floating"
                    >
                        Confirm SMS Code
                    </IonLabel>

                    <IonInput
                        onIonChange={e => setConfirmCodeRegister(e)}
                    />

                    <IonButton
                        slot="end"
                        onClick={checkConfirmCodeRegister}
                        disabled={
                            confirmCodeRegister.length < 6
                        }
                    >
                        Confirm
                    </IonButton>
                </IonItem>
            </IonList>
        </div>
    );
};

export default RegisterForm;
