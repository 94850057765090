import { useState } from 'react';
import { useHistory } from 'react-router';

export const LoginFormHooks = () => {
    const [mobilePhoneLogin, setMobilePhoneLogin] = useState<string>('')
    const [confirmCodeLogin, setConfirmCodeLogin] = useState<string>('')
    const history = useHistory()

    return {
        goToRegisterPage() {
            history.push('/register')
        },
        setMobilePhoneLogin(e: any) {
            setMobilePhoneLogin(e.target.value)
        },
        setConfirmCodeLogin(e: any) {
            setConfirmCodeLogin(e.target.value)
        },
        checkMobilePhoneLogin() {
            console.log('mobilePhoneLogin----->', mobilePhoneLogin)
        },
        checkConfirmCodeLogin() {
            console.log('confirmCodeLogin----->', confirmCodeLogin);
        },
        mobilePhoneLogin,
        confirmCodeLogin
    }
}
